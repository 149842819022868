<template>
  <div class="new-window text-lightblue">
    <screen
      :modeType="16"
      :winIndex="winIndex"
      :isPatrol="isPatrol"
      :isGroup="isGroup"
      :isCollect="isCollect"
      :user="user"
    />
  </div>
</template>

<script>
import Screen from '@/views/window/Screen';
import {
  clearStorage,
  /* getStorage,  */ setFlagArr,
  /* setStorage, */
  getParamValue,
} from '@/tools';
// let socket = getStorage('socketId');
window.addEventListener('beforeunload', () => {
  setFlagArr();
  //清空轮询预案视频拉流
  clearStorage('patrolCamera');
});
export default {
  name: 'SixteenWindow',
  data() {
    return {
      winIndex: -1,
      isPatrol: false,
      user: '',
      isGroup: '0',
      isCollect:'0'
    };
  },
  components: {
    Screen,
  },
  created() {
    this.winIndex = getParamValue('index');
    this.isPatrol = getParamValue('isPatrol');
    this.user = getParamValue('username');
    //组
    this.isGroup = getParamValue('isGroup');
    this.isCollect = getParamValue('isCollect');
  },
  computed: {},
  watch: {},
  methods: {},
  /* destroyed() {
    clearStorage('cameraList');
    clearStorage('patrolCameral');
    this.$socket.onclose();
  }, */
};
</script>

<style scoped>
.new-window {
  width: 100%;
  height: 100%;
  /* background: rgba(31, 64, 103, 1); */
  background: rgb(0, 0, 0);
}
</style>
